<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- from -->
        <n-field-datetime v-model="model.time1" :format="DATE_FORMAT.YMD_HM_3" field-name="time1" label="from" :error="error.time1" :disabled="isEdit || disabled" />
        <!-- to -->
        <n-field-datetime v-model="model.time2" :format="DATE_FORMAT.YMD_HM_3" field-name="time2" label="to" :error="error.time2" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- loginRestriction -->
        <n-field-enum v-model="model.loginRestriction" field-name="loginRestriction" enum-name="LoginRestriction" label="loginRestriction" :error="error.loginRestriction" :disabled="disabled" />
        <!-- tradingRestriction -->
        <n-field-enum v-model="model.tradingRestriction" field-name="tradingRestriction" enum-name="TradingRestriction" label="tradingRestriction" :error="error.tradingRestriction" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- editingRestriction -->
        <n-field-enum v-model="model.editingRestriction" field-name="editingRestriction" enum-name="EditingRestriction" label="editingRestriction" :error="error.editingRestriction" :disabled="disabled" />
        <!-- transferRestriction -->
        <n-field-enum v-model="model.transferRestriction" field-name="transferRestriction" enum-name="TransferRestriction" label="transferRestriction" :error="error.transferRestriction" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>
<script>
import { DATE_FORMAT } from '@/constant/format';
import NModal from '@/components/common/NModal';
import { add, validate } from '@/api/company/company-holiday';

export default NModal.extend({
  name: 'ModalCompanyHoliday',
  components: { NModal },

  data() {
    return {
      DATE_FORMAT,
    };
  },

  methods: {
    doSubmit(model) {
      return add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
