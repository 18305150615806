import { $fetch, $post, $put, $delete } from '@/services/axios';

export function validate(data) {
  return $post(`company/holiday/validate`, data);
}

export function add(data) {
  return $put(`company/holiday`, data);
}

export function fetch(data) {
  return $fetch(`company/${data.companyId}/holidays`);
}

export function remove(data) {
  return $delete(`company/holiday/${data.id}/${data.version}`);
}
